body{
	display:block;
	font-size: 1vw !important;
}

input:invalid{
	border: 2px solid red;
}

.example-box {
	width: 60%;
	border: solid 1px #ccc;
	color: rgba(0, 0, 0, 0.87);
	background: #fff;
	border-radius: 6px;
	position: absolute;
	z-index: 100;
	padding: 10px;
	top: 50px;
	right: 100px;
	transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
				0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  
  .example-box:active {
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
				0 8px 10px 1px rgba(0, 0, 0, 0.14),
				0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }

  .example-handle {
	cursor: move;
	width: 24px;
	height: 24px;
  }

.example-cerrar{
	position:absolute;
	right: 10px;
	top:10px;
	color:black;
	display:inline-flex;
}

.example-scrol{
	position:absolute;
	right: 10px;
	bottom: 50px;
	color:black;
}

.example-scrol:hover{
	background-color:slategrey
}

.avisofondoclaro{
	background-color: #D5D1F0 !important;
	color: #57565E !important;
	font-weight: 800 !important;
	opacity: 85% !important;
}

.mat-badge-content {
    font-size: 8px;
}    

.barraprincipalapp{
	top: 0;
	position:relative;
	display: inline-flex;
	width: 100%;
	height: 60px;
	color: white;
	background-color:#19123B;
}

.barraprincipalcliente{
	width: 35%;
	height: 40px;
	padding: 5px;
	padding-left: 20px;
	color: white;
	background-color:#19123B;
}

.titulobarraprincipalapp{
	width: 35%;
	height: 40px;
	padding:5px;
	text-align: center;
	color: white;
	background-color:#19123B;
}

.trans{
  position: relative;
  transition-property: font-size;
  transition-duration: 1s;
}

.trans:hover{
  transition-property: font-size;
  transition-duration: 1s;
  font-size: 20pt;
}

.titulobarraprincipalapp img{
	width: 55px;
}

.spacer{
	width:3%;
    flex: 1 1 auto;
}  

.infobarraprincipalapp{
	display: inline-flex;
	height: 40px;
	padding-top: 10px;
	padding-right: 30px;
	text-align: right;
	color: white;
	background-color:#19123B;
}

.botontransparente{
	background: none;
	border: none;
	color: white;
}

.sindecorado{
	text-decoration: none !important;
	color: black;
}

.sindecorado:hover{
	text-decoration: none !important;
	color: black;
}

.barraprincipaltabsapp{
	display: inline-flex;
	width: 100%;
	height: 40px;
	padding-left: 10px;
	padding-bottom: 0px;
	color: white;
	background-color:#19123B;
}

.tabprincipal{
	width:150px;
	height: 100%;
	text-align: center;
	padding-top: 5px;
	font-size: 14pt;
	color: white;
}

.tabprincipal a{
	color: white;
}

.tabprincipal a:hover{
	text-decoration: none;
	color: white;
	cursor: pointer;
	background-color:#19123B;
}

.tabprincipal:hover{
	cursor: pointer;
	background-color:#19123B;
}

.tabprincipal.active{
	color: #19123B;
	background-color: white !important;
}

.tabprincipal.active a{
	color: #19123B;
	background-color: white !important;
}



.titulo-principal{
	font-size: 1vw;
}

.navigation{
	padding: 0px !important;
}

.navigation .navbar{
	border-radius: 0px !important;
	background: #e53c37 !important;
	border: none;
	height: 22px;
}

.navigation .navbar *{
	color: white !important;
	cursor: pointer;
}

.nav-link{
  height: 10px;
  padding: 2px !important;
  padding-bottom: 0px;
  display: inline !important;
  margin-left: 3px;
}

.centrado{
	text-align: center;
}

.h1-strong{
	font-size: 1vw;
	padding-bottom: 1vw;
	margin-bottom: 1vw;
	margin-top: 2vw;
	border-bottom: 2px solid #eee;
	text-align: center;
	font-style: bold;
}

.h1-strong2{
	font-size: 2vw;
	padding-bottom: 1vw;
	margin-bottom: 1vw;
	margin-top: 2vw;
	text-align: center;
	font-style: bold;
}

.h2-strong{
	font-size: 1vw;
	padding-bottom: 1vw;
	text-align: center;
	font-style: bold;
}

.active{
	background: #cc1717 !important;
}


.jumbotron{
	margin: auto;
	margin-top: 20px;
	padding: 2vw;
	border-radius: 20px;
	text-align: center;
}

.but{
	margin: 1vw;
}